import React from 'react'
import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import AppLayout from '../components/AppLayout';
import AuthService from '../services/AuthService';


// import SEO from '../components/seo'

import {
    // Grid,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Grid,
    Divider,
    FormControl,
    FormGroup,
  } from '@material-ui/core'

import iconGrowth from '../images/icon-growth.png'
import iconExercise from '../images/icon-exercise.png'
import iconReport from '../images/icon-report.png'
// import iconGraph from '../images/icon-graph.png'
import pumpSmartIcon from '../images/pumpsmart-icon.png';
// import pumpSmartSplash from '../images/pumpsmart-splash.jpg';


const styles = theme => ({
    root: {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
      // flexGrow: 1,
      padding: theme.spacing.unit * 3,
      // backgroundImage: `url(${pumpSmartSplash})`,
      // backgroundPosition: 'center',
      // backgroundSize: 'contain',
      // opacity: '90',
      // backgroundPosition: 'left top'
    },
    // grow: {
    //   flexGrow: 1,
    // },
    // heading1: {
    //   fontSize: '3rem',
    //   padding: 10,
    // },
    // menuButton: {
    //   marginLeft: -12,
    //   marginRight: 20,
    // },
    // fab: {
    //   position: 'absolute',
    //   bottom: theme.spacing.unit * 2,
    //   right: theme.spacing.unit * 3,
    // },
    // extendedIcon: {
    //   marginRight: theme.spacing.unit,
    // },
  });


class IndexPage extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
    };
  
    static defaultProps = {
    };
  
    constructor(props) {
        super(props);
  
        this.state = {
          start: false,
        };
    }
  
    componentDidMount() {
      this.auth = new AuthService();
      if (this.auth.loggedIn()) {
          navigate('/home');
      }
    }
  
    
    onChange = (e) => {
      // let obj = {};
      // obj[e.target.name] = e.target.value;
      // this.setState(obj);
      this.setState({
        [e.target.name]: e.target.value,
      })
      
      // console.log('e.target', e.target);
      // console.log('e.target.name', );
      // console.log('e.target.value', e.target.value);
    };
  
    onSubmit = (e) => {
      // console.log('email', this.state.email);
      // console.log('password', this.state.password);
      this.auth.login(this.state.email, this.state.password);
    };

    onCreateAccountClicked = ()  => {
      navigate('/register');
    };
    
    onLoginClicked = ()  => {
      navigate('/login');
    };
  
    renderSplash() {
      const {
          classes,
          theme,
      } = this.props;

      const {
        email,
        password,
      } = this.state;
      
      //{/* <SEO title="PumpSmart: Sexual Performance Tracker" keywords={[`PumpSmart`, `sex performance tracker`]} /> */}
      return (
        <div className={classes.root}>
          <div style={{marginTop: '1rem', marginBottom: 20, textAlign: 'center'}}>
            <img src={pumpSmartIcon} width="100" />
            <br /><br />
            {/* <Typography variant="h1" align="center" style={{fontSize: '2.5rem', marginBottom: 20}}>PumpSmart</Typography> */}
            <Typography variant="h5" align="center" style={{fontWeight: 'normal', fontSize: '1.5rem'}}>PumpSmart™ is an app that gives you insights on your erection performance.</Typography>
          </div>
          
          <Divider />

          <Grid container justify="center">
            <List>
              {[
                {
                  // stepNum: 1,
                  text: 'Log Pump Sessions',
                  icon: iconExercise,
                },
                {
                  // stepNum: 2,
                  text: 'Track Erection Quality and Size',
                  icon: iconReport,
                },
                {
                  // stepNum: 3,
                  text: 'Get Insights into your Gains',
                  icon: iconGrowth,
                  // icon: iconGraph,
                }
              ].map(feature => {
                return (
                  <ListItem key={feature.text}>
                    <ListItemIcon>
                      <img src={feature.icon} alt="" width="36" height="36" />
                    </ListItemIcon>
                    <ListItemText primary={feature.text} />
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          <Divider />
          <br />

          <FormGroup>
            <Button variant="contained" color="primary" onClick={this.onCreateAccountClicked}>Create Account</Button>
          </FormGroup>
          <br />
          <FormGroup>
            <Button variant="contained" color="default" onClick={this.onLoginClicked}>Log In</Button>
          </FormGroup>
          
          
          
          {/* <Typography variant="h6" align="center" style={{marginTop: 50, marginBottom: 50}}>Get started below, or Sign-In</Typography> */}

          {/* <center>
          <div>
            <TextField
              id="outlined-email-input"
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              onChange={this.onChange}
              value={email}
            />
          </div>
          <div>
            <TextField
              id="outlined-password-input"
              label="Password"
              name="password"
              type="password"
              autoComplete="current-password"
              margin="normal"
              variant="outlined"
              onChange={this.onChange}
              value={password}
            />
          </div>

          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Get Started
          </Button>
          </center> */}
        </div>
      );
    }

    renderGetStarted() {
      const {
        classes,
        theme,
      } = this.props;

      const {
        email,
        password,
      } = this.state;

      return (
        <AppLayout>
          <div className={classes.root}>
            Get started.
          </div>
        </AppLayout>
      );
    }

    render() {
      if (this.state.start === false) {
        return this.renderSplash();
      } else if (this.state.start === true) {
        return this.renderGetStarted();
      }
    }
}
  

export default withRoot(withStyles(styles, { withTheme: true })(IndexPage));